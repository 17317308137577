import React, { ReactNode } from "react";
import { ConfigProvider, theme } from "antd";
import { StoreProvider } from "./StoreProvider";
import { SocketProvider } from "./SocketProvider";
import { ThemeProvider } from "./ThemeProvider";
import { AppProvider } from "./AppProvider";
import { HotelExtraInfoProvider } from "./ExtraInfoProvider";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "../app/store";

export const CombinedContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <StoreProvider>
      <PersistGate loading={null} persistor={persistor}>
        <SocketProvider>
          <ThemeProvider>
            <ConfigProvider
              theme={{
                algorithm: theme.compactAlgorithm,
                token: { 
                  colorPrimary: "#148898",
                },
              }}
            >
              <HotelExtraInfoProvider>
                <AppProvider>{children}</AppProvider>
              </HotelExtraInfoProvider>
            </ConfigProvider>
          </ThemeProvider>
        </SocketProvider>
      </PersistGate>
    </StoreProvider>
  );
};
