import React, { useContext } from "react";
import ReactSlider from "react-slider";
import { ThemeContext } from "../../providers";

interface RangeSliderInputProps {
  data: any;
  component: any;
  onChange: (component: any, value: any) => void;
  args?: any;
}

export const SliderInput = (props: RangeSliderInputProps) => {
  const { theme } = useContext(ThemeContext);
  const { data, component, onChange } = props;
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "5px", flex:1}}>
      <div className="text-nowrap" style={{ color: theme.colors.textPrimary }}>
        {component?.min}
      </div>
      <ReactSlider
        className="horizontal-slider"
        thumbClassName="uiSlider-thumb"
        trackClassName="uiSlider-track"
        min={component.min}
        max={component.max}
        step={component.step}
        value={data[component.id]}
        onChange={(value) => onChange(component, value)}
        renderThumb={(props, state) => (
          <div {...props} className="uiSlider-thumb">
            {state.valueNow}
          </div>
        )}
      />
      <span
        className="mr-2 text-nowrap"
        style={{ color: theme.colors.textPrimary }}
      >
        {component.max}
      </span>
    </div>
  );
};
