import React, { useContext } from "react";
import ReactSlider from "react-slider";
import { ThemeContext } from "../../providers";

interface RangeSliderInputProps {
  data: any;
  component: any;
  onChange: (component: any, value: any) => void;
  args?: any;
}

export const RangeSliderInput = (props: RangeSliderInputProps) => {
  const { theme } = useContext(ThemeContext);
  const { data, component, onChange } = props;
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "5px",flex:1}}>
      <div className="text-nowrap" style={{ color: theme.colors.textPrimary }}>
        {component.min}
      </div>
      <ReactSlider
        className="horizontal-slider"
        thumbClassName="uiSlider-thumb"
        trackClassName="uiSlider-track-range"
        min={component.min}
        max={component.max}
        step={component.step}
        onChange={(value) => onChange(component, value)}
        //@ts-ignore
        defaultValue={component.default_value}
        ariaLabelledby={["first-slider-label", "second-slider-label"]}
        ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
        renderThumb={(props, state) => (
          <div {...props} className="uiSlider-thumb">
            {state.valueNow}
          </div>
        )}
        pearling
        minDistance={10}
      />
      <div className="text-nowrap" style={{ color: theme.colors.textPrimary }}>
        {component.max}
      </div>
    </div>
  );
};
