import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import "./styles.css";
import { ComponentTypes, ListOfStationaryComponents } from "@twin-shared";
import { getDefaultValues } from "@twin-shared/lib/utility/getDefaultValues";
import { useSocketContext } from "../../providers";
import { ThemeContext } from "../../providers/ThemeProvider";
import { Form } from "antd";
import { updateFormData } from "./messageSlice";
import { useDispatch } from "react-redux";
import {
  ActionButton,
  AntdIcon,
  DateInput,
  DateRangeInput,
  Dropdown,
  FileInput,
  FlightMultiSegment,
  FlightSegment,
  FlightSummary,
  FlightTravelListSummary,
  HotelRoom,
  HotelSummary,
  HotelTravelListSummary,
  HtmlView,
  List,
  NumberInput,
  PasswordInput,
  RadioButtonInput,
  RadioInput,
  AutoCompleteInput,
  RangeSliderInput,
  Row,
  SelectInput,
  SliderInput,
  SubmitButton,
  SwitchInput,
  TextInput,
  TimeInput,
  TimeRangeInput,
  Unknown,
  VideoPlayback,
  Image,
  Tooltip,
  Label,
  CheckBoxInput,
  ChartScatter,
  ChartBar,
  ChartPie,
  ChartLine,
  AudioPlayback,
  ThemeSwitch,
  TakePhotoInput
} from "../../components";

interface UiComponentProps {
  message: any;
  message_id?: string;
}

export const UiComponent = ({ message, message_id }: UiComponentProps) => {
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);
  const initialValues = useMemo(() => getDefaultValues(message), [message]);
  const { sendUiResponse, sendUiComponentChange, sendUiComponentQuery } =
    useSocketContext();
  const [form] = Form.useForm();
  const [data, setData] = React.useState<any>(initialValues);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const ASTERISKWIDTH =14

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);  
    }; 
    window.addEventListener('resize', handleResize); 
    return () => window.removeEventListener('resize', handleResize);
  }, []); 
   

  useEffect(() => {
    if (
      Object.keys(initialValues).length > 0 &&
      Object.keys(data).length === 0
    ) {
      setData(initialValues);
    }
  }, [initialValues]);

  const noView = useMemo(
    () =>
      message.payload?.children.every((a: any) =>
        ListOfStationaryComponents.includes(a.type)
      ),
    [message.payload?.children]
  );

  useEffect(() => {
    dispatch(updateFormData(data));
  }, [data]);

  const getError = (key: string) => {
    return message.payload.errors?.[key];
  };

  const onChange = useCallback(
    (component: any, value: any) => {
      setData((prev: any) => ({
        ...prev,
        [component.id]: value,
      }));

      form.setFieldsValue({ [component.id]: value });

      if (component.send_on_change) {
        sendUiComponentChange(message.messageId, component.id, value);
      }
      if (component.submit_on_change) {
        sendUiResponse(message.messageId, {
          ...data,
          [component.id]: value,
        });
      }
    },
    [message, setData, data]
  );

  const InputWrapper = useCallback(
    ({ component, children }: any) => {
      const error = getError(component.id); 
      let label_width= component.label_width;
      const required_= component.validation?.required;
      if(required_ && label_width >ASTERISKWIDTH){
        label_width=label_width-ASTERISKWIDTH;
      }  

      return (
        <Form.Item
          key={component.id} 
          label={(component.type=== ComponentTypes.CheckBoxInput  && component.label_position=== 'right') ? '': (
              <span style={{
                color: theme.colors.textPrimary,
                textAlign: component.label_text_align,
                width:  window.innerWidth < 640 ?  '100%'  : component.label?.length > 0 && label_width ?  label_width : "none",
                }}>
                {component.label}
              </span> 
            )
          }
          name={component.id}
          validateStatus={error ? "error" : undefined}
          help={error}
          style={{ marginBottom: 4, color: theme.colors.textPrimary }}
          rules={[
            {
              required: required_,
              message: `This field is required.`,
            },
          ]}
        >
          <div 
          style={{
            display:'flex', 
            overflow:'visible',
            flexWrap:'wrap'}}
            >
            {component.label_icon && (
              <AntdIcon
                type={component.label_icon}
                style={{ marginRight: 4 }}
                key={`icon-${component.id}`}
              />
            )}
            {children}
          </div>
        </Form.Item>
      );
    },
    [message, theme.isDark]
  );

  const renderInput = useCallback(
    (component: any, args: any = {}) => {
      const { extra, onExtraClick, ...rest } = args;
      switch (component.type) {
        /**
         * TextInput
         *
         */
        case ComponentTypes.TextInput:
          return <TextInput {...{ component, data, args, extra, onChange }} />;

        /**
         * PasswordInput
         *
         */
        case ComponentTypes.PasswordInput:
          return (
            <PasswordInput {...{ component, data, args, extra, onChange }} />
          );

        /**
         * NumberInput
         *
         */
        case ComponentTypes.NumberInput:
          return <NumberInput {...{ component, data, args: rest, onChange }} />;

        /**
         * RangeSliderInput
         *
         */
        case ComponentTypes.RangeSliderInput:
          return (
            <RangeSliderInput {...{ component, data, args: rest, onChange }} />
          );

        /**
         * SliderInput
         *
         */
        case ComponentTypes.SliderInput:
          return <SliderInput {...{ component, data, args: rest, onChange }} />;

        /**
         * SwitchInput
         *
         */
        case ComponentTypes.SwitchInput:
          return <SwitchInput {...{ component, data, args: rest, onChange }} />;

        /**
         * DateInput
         *
         */
        case ComponentTypes.DateInput:
          return <DateInput {...{ component, data, args: rest, onChange }} />;

        /**
         * DateRangeInput
         *
         */
        case ComponentTypes.DateRangeInput:
          return (
            <DateRangeInput {...{ component, data, args: rest, onChange }} />
          );

        /**
         * TimeInput
         *
         */
        case ComponentTypes.TimeInput:
          return <TimeInput {...{ component, data, args: rest, onChange }} />;

        /**
         * TimeRangeInput
         *
         */
        case ComponentTypes.TimeRangeInput:
          return (
            <TimeRangeInput {...{ component, data, args: rest, onChange }} />
          );

        /**
         * SelectInput
         *
         */
        case ComponentTypes.SelectInput:
          return (
            <SelectInput
              allowMultiSelect={false}
              {...{ component, data, args: rest, onChange }}
            />
          );

        /**
         * MultiSelectInput
         *
         */
        case ComponentTypes.MultiSelectInput:
          return (
            <SelectInput
              allowMultiSelect={true}
              {...{ component, data, args: rest, onChange }}
            />
          );
         /**
         * TakePhotoInput
         *
         */
        case ComponentTypes.TakePictureButton:
          return (<TakePhotoInput
              {...{ component, data, args: rest, onChange }}
            />); 
        /**
         * FileInput
         *
         */
        case ComponentTypes.FileInput:
          return <FileInput {...{ component, data, args: rest, onChange }} />;

        /**
         * RadioInput
         *
         */
        case ComponentTypes.RadioInput:
          return (
            <RadioInput
              {...{ component, data, args, extra, onExtraClick, onChange }}
            />
          );

        /**
         * RadioButtonInput
         *
         */
        case ComponentTypes.RadioButtonInput:
          return (
            <RadioButtonInput
              {...{ component, data, args, extra, onExtraClick, onChange }}
            />
          );

        /**
         * AutoCompleteInput
         *
         */
        case ComponentTypes.AutoCompleteInput:
          return (
            //@ts-ignore
            <AutoCompleteInput
              sendUiComponentQuery={sendUiComponentQuery}
              message={message}
              {...{ component, data, args: rest, onChange }}
            />
          );

        /**
         * CheckBoxInput
         *
         */
        case ComponentTypes.CheckBoxInput:
          return (
            <CheckBoxInput {...{ component, data, args, extra, onChange }} />
          );

        /**
         * Unknown
         *
         */
        default:
          return <Unknown {...{ component }} />;
      }
    },
    [message, data]
  );

  const renderComponent = useCallback(
    (component: any, index?: number) => {
      const type = component.type;

      if (type === ComponentTypes.Form) {
        return (
        <Form
            form={form}
            colon={false}
            key={component.id}
            initialValues={initialValues}
            onFinish={() => {
              sendUiResponse(message.messageId, data);
              dispatch(updateFormData(data));
            }}
            {...component.ui_args?.web}
            className={noView ? "h-0" : ""}  
            labelAlign={component.label_align}
            labelWrap={component.label_wrap}
            >
            {component.title && (
              <Form.Item
                style={{ marginBottom: 4, color: theme.colors.textPrimary }}
                label={ 
                  <span style={{ color: theme.colors.textPrimary }}>
                    {component.title}
                  </span>
                }
                key={`form-item-${component.id}`}
              />
            )}
            {component.children.map((child: any, index: number) =>
              renderComponent(child, index)
            )}
          </Form>
        );
      }

      if (type.startsWith("input:")) {
        return (
          <InputWrapper component={component} key={component.id}>
            {renderInput(component, component.ui_args?.web)}
          </InputWrapper>
        );
      }

      switch (type) {
        /**
         * Row
         *
         */
        case ComponentTypes.Row:
          return (
            <Row
              component={component}
              render={renderComponent}
              key={`row-${index}`}
            />
          );

        /**
         * InputWithAddons
         *
         */
        case ComponentTypes.InputWithAddons:
          return (
            <InputWrapper component={component.children[0]} key={component.id}>
              {renderInput(component.children[0], {
                ...component.children[0].ui_args?.web,
                addonBefore: component.before
                  ? renderInput(component.before)
                  : null,
                addonAfter: component.after
                  ? renderInput(component.after)
                  : null,
              })}
            </InputWrapper>
          );

        /**
         * Tooltip
         *
         */
        case ComponentTypes.Tooltip:
          return <Tooltip component={component} render={renderComponent} />;

        /**
         * Image
         *
         */
        case ComponentTypes.Image:
          return <Image component={component} />;

        /**
         * SubmitButton
         *
         */
        case ComponentTypes.SubmitButton:
          return (
            <SubmitButton
              component={component}
              key={`submit-button-${index}`}
            />
          );

        /**
         * ActionButton
         *
         */
        case ComponentTypes.ActionButton:
          return (
            <ActionButton
              //@ts-ignore
              messageid={message_id ?? message["messageId"]}
              component={component}
              data={data}
              key={`action-button-${index}`}
            />
          );
        
        /**
         * List
         *
         */
        case ComponentTypes.List:
          return (
            <List
              component={component}
              render={renderComponent}
              key={`twin-list-${index}`}
            />
          );

        /**
         * DropDown
         *
         */
        case ComponentTypes.DropDown:
          return (
            <Dropdown
              component={component}
              render={renderComponent}
              key={`twin-dropdown -${index}`}
            />
          );

        /**
         * Label
         *
         */
        case ComponentTypes.Label:
          return <Label component={component} key={`label-${index}`} />;

        /**
         * FlightSummary
         *
         */
        case ComponentTypes.FlightSummary:
          return (
            <FlightSummary
              args={component.args}
              key={`flight-summary-${index}`}
            />
          );

        /**
         * FlightSegment
         *
         */
        case ComponentTypes.FlightSegment:
          return (
            <FlightSegment
              args={component.args}
              key={`flight-segment-${index}`}
            />
          );

        /**
         * FlightMultiSegment
         *
         */
        case ComponentTypes.FlightMultiSegment:
          return (
            <FlightMultiSegment
              args={component.args}
              children={component.children}
              render={renderComponent}
              key={`flight-multi-segment-${index}`}
            />
          );

        /**
         * HotelSummary
         *
         */
        case ComponentTypes.HotelSummary:
          return (
            <HotelSummary
              component={component}
              key={`hotel-summary-${index}`}
            />
          );

        /**
         * HotelRoom
         *
         */
        case ComponentTypes.HotelRoom:
          return (
            <HotelRoom component={component} key={`hotel-room-${index}`} />
          );

        /**
         * FlightSummaryTravelList
         *
         */
        case ComponentTypes.FlightSummaryTravelList:
          return (
            <FlightTravelListSummary
              component={component}
              key={`flight-travel-list-summary-${index}`}
            />
          );

        /**
         * HotelSummaryTravelList
         *
         */
        case ComponentTypes.HotelSummaryTravelList:
          return (
            <HotelTravelListSummary
              component={component}
              key={`hotel-travel-list-summary-${index}`}
            />
          );

        /**
         * Html View
         */
        case ComponentTypes.HTMLView:
          return (
            <HtmlView
              key={"row-item-htmlview-" + component.id}
              htmlContent={component?.html}
            />
          );
        /**
         * Video View
         */
        case ComponentTypes.VideoPlayback:
          return <VideoPlayback component={component} />
        
         /**
         * Audio View
         */
         case ComponentTypes.AudioPlayback:
          return <AudioPlayback component={component} /> 

        /**
         * Charts View
         */
        case ComponentTypes.ChartScatter:
          return <ChartScatter allargs={component} />;

        case ComponentTypes.ChartLine:
          return <ChartLine allargs={component} />;

        case ComponentTypes.ChartBar:
          return <ChartBar allargs={component} />;

        case ComponentTypes.ChartPie:
          return <ChartPie allargs={component} />;

     
        /**
         * YourCart
         *
         */
        case ComponentTypes.YourCart:
        case ComponentTypes.YourList:
          return <></>;

        /**
         * Theme Switch
         */
        case ComponentTypes.ThemeSwitch:
          return <ThemeSwitch args={component.args} />;

        /**
         * Unknown
         *
         */
        default:
          return <Unknown {...{ component }} />;
      }
    },
    [message, data, theme.isDark]
  );

  return renderComponent(message.payload);
};
