import { createSlice } from "@reduxjs/toolkit";
import { MessageSender, MessageType } from "./interfaces";
import { randomId, filterInputs } from "@twin-shared";

interface Message {
  messageId: string;
  payload: any;
  type: MessageType;
  sender: MessageSender;
  createdAt: number;
}
export interface MessageState {
  formData: any;
  list: Array<Message>;
  mimiIsTyping: boolean;
  isRecordingActive: boolean; 
  activeVideoId:  any;
 }

const initialState: MessageState = {
  formData: {},
  list: [],
  mimiIsTyping: false, 
  isRecordingActive: false,
  activeVideoId:undefined
};

const takeRight = (array: Array<any>, n = 1) =>
  n === 0 ? [] : array.slice(-n);

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    addMessage: (state, action) => {
      const { payload, type, sender, messageId } = action.payload;
      let uniqueMessages: Array<Message> = [];
      //remove duplicate messages
      if (messageId) {
        uniqueMessages = state.list.filter(
          (msg: Message) => msg.messageId !== messageId
        );
      }
      const id = messageId || randomId();
      if (uniqueMessages.length === 0) {
        state.list = [
          ...takeRight(state.list, 19),
          {
            messageId: id,
            payload,
            createdAt: Date.now(),
            type,
            sender,
          },
        ];
      } else {
        state.list = [
          ...takeRight(uniqueMessages, 19),
          {
            messageId: id,
            payload,
            createdAt: Date.now(),
            type,
            sender,
          },
        ];
      }
    },
    updateMessage: (state, action) => {
      const { message_id, errors } = action.payload;
      const hasErrors = errors && Object.keys(errors).length > 0;
      const { payload } =
        state.list.find((item) => item.messageId === message_id) || {};
      if (hasErrors) {
        state.list = state.list.map((item) => {
          if (item.messageId === message_id) {
            return {
              ...item,
              payload: {
                ...payload,
                errors,
              },
            };
          } else {
            return item;
          }
        });
        return;
      }

      const inputs = filterInputs(payload);
      const questions = inputs.map(
        ({
          label,
          placeholder,
          parentTitle,
        }: {
          label: string;
          placeholder: string;
          parentTitle: string;
        }) => label || placeholder || parentTitle
      );

      const answers = inputs.map(
        ({ type, id }: { type: string; id: string }, index: number) => {
          if (type === "input:password") {
            return "********";
          }

          if (type === "input:file") {
            const files = state.formData[id] ?? [];
            return files.map((file: any, index: number) => {
              if (files.length - 1 === index) {
                return `${file.metadata?.name} uploaded`;
              }
              return `${file.metadata?.name} uploaded\n`;
            });
          }

          const input = inputs[index];
          if (input.options) {
            const selectedOption = input.options.find(
              (option: any) => option.value === state.formData[id]
            );
            return selectedOption
              ? selectedOption.label ?? selectedOption.value
              : state.formData[id];
          }

          return state.formData[id];
        }
      );

      const index = state.list.findIndex(
        (item) => item.messageId === message_id
      );
      state.list = [
        ...state.list.splice(0, index),
        {
          messageId: `${message_id}-questions`,
          type: "text",
          payload: questions.join("\n"),
          createdAt: Date.now(),
          sender: "mimi",
        },
        {
          messageId: `${message_id}-answers`,
          type: "text",
          payload: answers.join("\n"),
          createdAt: Date.now(),
          sender: "user",
        },
        ...state.list.splice(index + 1, state.list.length),
      ];
    },
    updateFormData: (state, action) => {
      state.formData = action.payload;
    },
    setMimiIsTyping: (state, action) => {
      state.mimiIsTyping = action.payload;
    },
    setActiveVideoId: (state, action) => {
      state.activeVideoId = action.payload;
    },
    setIsRecordingActive: (state, action) => {
      state.isRecordingActive = action.payload;
    },
   },
});

export const { addMessage, updateMessage, updateFormData,setMimiIsTyping, setActiveVideoId, setIsRecordingActive } =
  messageSlice.actions;
export default messageSlice.reducer;
