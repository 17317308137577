import { useMemo } from "react";

export const Row = ({
  component,
  render,
}: {
  component: any;
  render: (c: any) => any;
}) => {
  const backgroundColor = component?.backgroundColor;
  const justifyContent = component?.justifyContent;
  const childrenFlex: Array<number> = component?.children_flex || [];
  const totalFlex = useMemo(
    () => childrenFlex.reduce((acc, curr) => acc + curr, 0),
    []
  );

  const getFlexWidth = (index: number) => {
    return `${(childrenFlex[index] / totalFlex) * 100}%`;
  };

  return (
    <div
      key={component.id}
      style={{
        display: "flex",
        flexDirection: "row",
        ...component.ui_args?.web,
        backgroundColor: backgroundColor || "transparent",
        justifyContent: justifyContent,
        wordBreak: "break-all",
        alignItems: "baseline",
        flexWrap: "wrap"
      }}
    >
      {component.children.map((child: any, index: number) => (
        <div
          data-testid={`child-${index}`}
          className={`${
            Array.isArray(childrenFlex) &&
            childrenFlex.length === 0 &&
            justifyContent === "none"
              ? "w-full"
              : ""
          }`}
          key={`div-${index}`}
          style={
            totalFlex > 0
              ? { width: getFlexWidth(index) }
              : {
                  flex:
                    justifyContent === "none"
                      ? 1
                      : component?.children_flex?.[index],
                }
          }
        >
          {render(child)}
        </div>
      ))}
    </div>
  );
};
